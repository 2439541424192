import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// Config
import config from 'config/website'
const Data = require('../../data/publications');
const masterData = require('../../content/json/masterData');
// Components
import VideoPageLayout from 'components/VideoPageLayout'
import PostMeta from 'components/PostMeta'
import MDXRenderer from 'components/Post/MDX'
// Hooks
import useWindowSize from 'hooks/useWindowSize'
// Styles
import 'styles/post.css'
import 'styles/syntax.css'

const transform = str => {
  const title = str
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase().replace(/\s+/g, ' ')
    .trim()
  return title.split(' ').join('-');
}

const fetchData = (id) => {
  return masterData.filter((i) => {
    return i.key === id;
  })[0];
};

const PostTemplate = ({ data, pageContext, location }) => {

  const { key, id } = pageContext;
  console.log(key, id);
  const result = Data.YouTube.filter((i) => {
    return i.id === id;
  })[0];
  const masterList = fetchData(result.id);
  const playlistItems = masterList?.value;

  // require('typeface-source-code-pro')
  const windowSize = useWindowSize()
  const author = config.author
  const desc = playlistItems && playlistItems[0]?.snippet?.description?.replace(/\n/g, "<br />");
  result.desc= desc;

  return (
    <>
      <PostMeta
        title={`Couse On ${result.snippet.title} | ${config.siteTitle} By Tkssharma`}
        description={`Couse On ${result.snippet.title} By tkssharma` }
        pathname={location?.pathname}
        thumbnail={result?.snippet?.thumbnails?.default?.url}
        url={`/blogs/`}
      />
      <VideoPageLayout
        post={result}
        location={location}
        context={pageContext}
        windowSize={windowSize}
      >
      </VideoPageLayout>
    </>
  )
}

export default PostTemplate
