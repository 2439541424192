import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Disqus } from 'gatsby-plugin-disqus'
// Views
import Footer from 'views/Footer'
// Components
import Nav from 'components/Nav'
import VideoHeader from 'components/VideoHeader'
import VideoContent from 'components/VideoContent'
import PostAuthor from 'components/PostAuthor'
import PageLink from 'components/PageLink'
import ScrollTop from 'components/ScrollTop'
// Config
import config from 'config/website'


const Wrapper = styled.div`
  background: rgba(255,255,255,0.985);
`

const HeroImage = styled.img`
background-repeat: no-repeat;
background-size: cover;
width:100%;
height: 100%;
margin-top: -100px;
`

const Separator = styled.hr`
  border-bottom: 2px solid rgba(0,0,0,0.095);
`

class PostLayout extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { post, location, context, windowSize, children } = this.props

    if (typeof __PREFIX_PATHS__ !== 'undefined' && __PREFIX_PATHS__) {
      rootPath = `${__PATH_PREFIX__  }/`
    }
    const disqusConfig = {
      url: `${config.siteUrl}${location.pathname}`,
      identifier: post.id,
      title: post.title,
    }

    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]')
    }

    return (
      <div className='font-default w-full m-auto'>
        <span id='top' />
        <Nav windowSize={windowSize} />
        {post?.snippet?.thumbnails?.standard?.url ? (
          <HeroImage
            className=''
            src={post.snippet.thumbnails.standard.url}
            alt={post.snippet.title}
          />
        ) : null}
        <Wrapper className='font-default m-0 pt-1 sm:pt-0 pb-0'>
          <VideoHeader post={post} />

          <VideoContent post={post} />
          <Separator className='w-5/6 border-none my-4 mt-8 mx-auto' />
          <Disqus
            className='w-9/10 md:w-4/5 lg:w-3/4 mx-auto mt-18 mb-20'
            config={disqusConfig}
          />
          <Footer />
        </Wrapper>
      </div>
    )
  }
}
export default PostLayout
