import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


const Wrapper = styled.header`
  @media (max-width: 500px) {
    &::after {
      left: 0;
    }
  }
  &::after {
    content: '';
    display: block;
    position:relative;
    background: linear-gradient(to top left, SlateBlue 30%, DeepSkyBlue 75%);
    width: 25%;
    height: 6px;
    top: -1.25rem;
    left: 0;
    @media (min-width: 900px) {
      height: 7px;
    }
    @media (min-width: 1100px) {
      height: 8px;
    }
  }
`

const PostTitle = styled.h1`
  width: 85%;
  @media (max-width: 600px) {
    font-size: 3.5rem;
  }
`

const PostHeader = ({ post }) => (
  <Wrapper className='font-default leading-normal tracking-tight w-9/10 max-w-300 mx-auto my-0' role='banner'>
        <div className="youtube-container">

<section className="section container mt-20">
  <div className="lesson-without-playlist-holder">
    <div className="lessons-frame-holder">
      <div className="lessons-frame ">
        <section className="section text-center">
          <div className="video-container">
            <iframe
              frameBorder="0"
              width="100%"
              height="500"
              allow='autoplay'
              allowFullScreen=""
              allowFullScreen=""
              src={`https://www.youtube.com/embed/videoseries?list=${post.id}`}
            />
          </div>
        </section>
        <p className="mt-8 p-20 bold text-xl" dangerouslySetInnerHTML={{ __html: post.desc }}></p>

      </div>
    </div>
  </div>
</section>
</div>
  </Wrapper>
)


export default PostHeader
